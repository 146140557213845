<template>
  <div
    id="home"
    class="full-width"
  >
    <header>
      <h1>En Français</h1>
    </header>
    <section>
      <h1>404 - Contenu non trouvé</h1>
      <code v-if="path">{{ path }}</code>
    </section>
  </div>
</template>

<script>

export default {
  name: 'NotFound',
  computed: {
    path () {
      if (this.$route.query.fullpath) {
        return this.$route.query.fullpath
      } else if (this.$route.params.pathMatch) {
        return this.$route.params.pathMatch
      } else {
        return null
      }
    }
  },
  created () {
    console.log('NotFound created', this.$route)
  },
  metaInfo () {
    return {
      title: 'Not Found'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
